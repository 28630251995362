<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table" v-if="adjustmentList.length">
                <thead>
                    <tr>
                        <th>Stock Adj Date</th>
                        <th>Stock Adj No</th>
                        <th>Warehouse</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in adjustmentList" :key="i">
                        <td>{{item.date}}</td>
                        <td>{{item.adjustment_no}}</td>
                        <td>{{item.warehouse?.name ?? 'N/A'}}</td>
                        <td>{{item.description}}</td>
                        <td>{{item.status}}</td>
                        <td><span style="cursor: pointer" @click="goToEdit(item.id)">View</span> </td>
                    </tr>
                </tbody>
            </table>
             <p v-else class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
import {defineProps} from 'vue'
import {useRoute, useRouter} from "vue-router";

const $router = useRouter();
const $route = useRoute();
const $props = defineProps({
    adjustmentList: {
        type: Array,
        default: []
    }
})

const goToEdit = (adjustmentId) => {
    $router.push({name: 'stock-adjustment-edit',
        params: {
            companyId: $route.params.companyId,
            moduleId: $route.params.moduleId,
            menuId: $route.params.menuId,
            pageId: $route.params.pageId,
            adjustmentId: adjustmentId
        },
        query: {
            start: $route.query.start,
            end:  $route.query.end
        }

    })
}
</script>
